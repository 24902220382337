
/*
 * Projects page
 */
.project {
  box-sizing: border-box;
  max-width: 100%;
  a {
    height: 100%;
    display: block;
    @include clearfix;
    padding: 1em;
    transition: background-color 0.5s ease;
    color: $base-font-color;
    &:hover, &:focus {
      color: $base-font-color;
      text-decoration: none;
      background-color: tint($action-color, 80%);
    }
  }
  h3 {
    margin-bottom: 0;
  }
  .project-info {
    font-family: $heading-font-family;
    text-transform: uppercase;
    color: $medium-gray;
    font-size: 0.9em;
  }
  .project-image {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  img {

  }
  .project-description {
    
  }
}

@include media($mobile) {
  .project-list {
    @include display(flex);
    @include flex-wrap(wrap);
  }
  .project {
    width: 50%;
  }
}

@include media($tablet) {
  .project {
    width: 33%;
  }
}

/*
 * Individual project pages
 */
.projects {
  .info {
    font-family: $heading-font-family;
    text-transform: uppercase;
    color: $medium-gray;
  }  

  iframe {
    max-width: 100%;
    padding: 10px 0;
  }
  .image-gallery {
    padding-top: 40px;
    img {
      box-shadow: 2px 2px 5px $light-gray;
      float: left;
      margin: 2px;
      transition: box-shadow 0.5s ease;
    }
    a:hover img, a:focus img {
      box-shadow: 2px 2px 15px $highlight-color;
    }
  }

  @include media($tablet) {
    #content {
      @include clearfix;
    }

    .with-media {
      box-sizing: border-box;
      width: 50%;
      float: left;
      padding-right: 20px;
    }

    .media {
      box-sizing: border-box;
      width: 50%;
      float: right;
    }

    .image-gallery {
      float: left;
    }
    
  }
}



